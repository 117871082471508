import { useEffect, useState } from 'react';
import { SminexLogo } from '@/shared/ui/icons/Sminex';
import { HeaderMenuBtn } from './header-menu-btn';
import { HeaderOptionsBtn } from './header-options-btn';
import { PhoneNumber } from './phone-number';
import { Menu } from './menu';
import styles from './header.module.css';
import { headerTexts } from '../model/headerPathText';
import { useLocation, useNavigate } from 'react-router-dom';
import { TabMenu } from './tab-menu';

export const Header = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflow = isMenuVisible ? 'hidden' : 'auto';
  }, [isMenuVisible]);
  const headerDescText: string = headerTexts[location.pathname] || 'Коротко о нас';

  const handleMenuClose = () => {
    setIsMenuVisible(false);
  };

  return (
    <>
      <div className={`${styles.headerContainer} ${isMenuVisible ? styles.clicked : ''}`}>
        <div className={styles.menuWithLogoCont}>
          <div className={`${styles.menuWithLogoWrap} ${isMenuVisible ? styles.clicked : ''}`}>
            <HeaderMenuBtn onClickFunc={() => setIsMenuVisible(!isMenuVisible)} isMenuVisible={isMenuVisible} />
          </div>
          <div
            className={`${styles.sminexLogoWrap} ${isMenuVisible ? styles.clicked : ''}`}
            onClick={() => navigate('/')}
          >
            <SminexLogo />
          </div>
        </div>
        {!isMenuVisible && <div className={styles.headerDesc}>{headerDescText}</div>}
        <div className={styles.optionsAndNumberBlock}>
          <div className={`${styles.headerOptionsWrap} ${isMenuVisible ? styles.clicked : ''}`}>
            Выбрать недвижимость
            <HeaderOptionsBtn />
          </div>
          <PhoneNumber phone="+7 495 324 72 11" isMenuVisible={isMenuVisible} />
        </div>
      </div>
      <div className={`${styles.menuWrapper} ${isMenuVisible ? styles.visible : styles.hidden}`}>
        <Menu onClose={handleMenuClose} />
      </div>
      <TabMenu setIsMenuVisible={setIsMenuVisible} isMenuVisible={isMenuVisible} />
    </>
  );
};
