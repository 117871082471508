import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface PhoneNumberProps {
  phone: string;
  isMenuVisible: boolean;
}

export const PhoneNumber = ({ phone, isMenuVisible }: PhoneNumberProps) => {
  return (
    <>
      <a href={`tel:${phone}`} {...stylex.props([styles.phoneNumber(isMenuVisible)])}>
        {phone}
      </a>
    </>
  );
};
