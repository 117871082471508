import { useNavigate } from 'react-router-dom';
import { tabMenuItems } from '../model/mock-items';
import styles from './tab-menu.module.css';

interface TabMenuPrpos {
  setIsMenuVisible: (item: boolean) => void;
  isMenuVisible: boolean;
}

export const TabMenu = ({ setIsMenuVisible, isMenuVisible }: TabMenuPrpos) => {
  const navigate = useNavigate();
  const handleClick = (title: string, url?: string) => {
    if (title === 'Меню') {
      setIsMenuVisible(!isMenuVisible);
    }
    if (title === 'Выбор недвижимости' || title === 'Избранное') {
      navigate(url!);
    }
  };
  return (
    <div className={`${!isMenuVisible ? styles.tabMenuContainer : styles.tabMenuContainerHidden}`}>
      {tabMenuItems.map((item, id) => {
        const IconComponent = item.btnIcon;
        return (
          <div className={styles.menuBtnWrap} key={id} onClick={() => handleClick(item.title, item.url)}>
            {item.title === 'Выбор недвижимости' ? (
              <div className={styles.circle}>
                <IconComponent />
              </div>
            ) : (
              <IconComponent />
            )}{' '}
            <p className={styles.text}>{item.title}</p>
          </div>
        );
      })}
    </div>
  );
};
