import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  phoneNumber: (isClicked: boolean) => ({
    cursor: 'pointer',
    color: isClicked ? colors.secondaryInverseText : colors.primaryIcon,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: {
      default: `${fonts.din2014Light}`,
      '@media (max-width: 767px)': `${fonts.din2014Regular}`,
    },
    textDecoration: 'none',
    lineHeight: {
      default: '140%',
      '@media (max-width: 767px)': '125%',
    },
    fontSize: {
      default: '1.0417rem',
      '@media (max-width: 1440px)': '1.387rem',
      '@media (max-width: 767px)': '4.267rem',
      '@media (max-width: 360px)': '4.445rem',
    },
  }),
});
